<template>
  <v-container class="px-10">
    <v-row>
      <v-col md="10">
        <slot name="avatar"></slot>
        <div class="mb-4">
          <slot name="company-name"></slot>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <div class="mb-3">
          <slot name="name"></slot>
        </div>
        <div class="mb-1">
          <slot name="phone"></slot>
        </div>
        <div class="mb-1">
          <slot name="email"></slot>
        </div>
        <v-card flat class="mt-10" color="light">
          <v-card-text>
            <div class="mb-4">
              <label class="font-weight-bold text-body-1" for="login">Login</label>
              <slot name="login"></slot>
            </div>
            <div class="mb-0">
              <label class="font-weight-bold text-body-1" for="password">Password</label>
              <slot name="password"></slot>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ClientLayout"
}
</script>